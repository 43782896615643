
import { computed, defineComponent } from 'vue';
import { useStore } from 'vuex';
import Header from '../components/header/HeaderBox.vue';

export default defineComponent({
  name: 'Home',
  components: {
    Header,
  },
  props: {
    cardColumn: Number,
  },
  setup() {
    const store = useStore();

    // 进行登陆判断
    const loginJudge = computed(() => {
      return store.state.user;
    });

    return {
      loginJudge,
    };
  },
});
